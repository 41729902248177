import Alert from "../../Alert";
import styled from "styled-components";
import NewButton from "../../NewButton";
import Icon from "../../Icon";
import { themeProp } from "../../../theme";
import { useString as s } from "../../../components/StringProvider";
import { Paragraph } from "../../Typography";
import { useChat } from "./ChatProvider";

const ChatError = () => {
  const errorMessage = s(
    "aiDiscoveryModal.popup.chatError.message",
    "Server error, request could not be processed"
  );
  const retryButton = s(
    "aiDiscoveryModal.popup.chatError.retryButton",
    "Retry"
  );

  const { handleRetry } = useChat();

  const content = (
    <Container>
      <Paragraph variant="body">{errorMessage}</Paragraph>
      <NewButton type={"iconTextPrimary"} onClick={handleRetry}>
        <Icon name={"loop"} size={"large"} />
        {retryButton}
      </NewButton>
    </Container>
  );
  return (
    <StyledAlert
      description={content}
      type="error"
      showIcon
      icon={<Icon name={"caution"} size={"large"} />}
    />
  );
};

const StyledAlert = styled(Alert)`
  max-width: 521px;
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }

  & .ant-alert-description {
    & > * {
      display: flex;
      align-items: center;
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    font-weight: ${themeProp(`typography.bMedium.fontWeight`)};
    color: ${themeProp(`palette.primary`)};
    padding: 0 !important;
  }
`;

export default ChatError;
